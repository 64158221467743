import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "store";
import { useAppSelector } from "store/hooks";
import { ITicketCountByCompany } from "hooks/get/admin-get-tickets-count-by-company";

export interface adminStateTypes {
  ticketsStatics: ITicketCountByCompany;
}

export const DEFAULT_USER = {
  name: "",
  lastName: "",
  email: "",
  _id: "",
  roles: [],
  isBlocked: false,
  phone: "",
  language: "en",
};

const initialState: adminStateTypes = {
  ticketsStatics: { closed: [], inProgress: [], waitingForAnswer: [] },
};

export const admin = createSlice({
  name: "admin",
  initialState,
  reducers: {
    updateTicketsStatics: (
      state: adminStateTypes,
      action: PayloadAction<ITicketCountByCompany>
    ) => {
      state.ticketsStatics = action.payload;
    },
  },
});

//
export const { updateTicketsStatics } = admin.actions;
//

export const SELECT_ADMIN_TICKET_STATICS = () =>
  useAppSelector((state: AppState) => state.admin.ticketsStatics);

export const SELECT_ADMIN_NEW_MESSAGES_BY_COMPANY = (companyId?: string) =>
  useAppSelector((state: AppState) =>
    state.admin.ticketsStatics.waitingForAnswer.find(
      (c) => c.company === companyId
    )
  );

export default admin.reducer;
