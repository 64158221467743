import { api } from "..";

export const adminGetTicketsCountByCompany = () =>
  api.get<ITicketCountByCompany>(`/admin/tickets/count-by-company`);

export interface ITicketCountByCompany {
  closed: {
    company: string;
    count: number;
  }[];
  inProgress: {
    company: string;
    count: number;
  }[];
  waitingForAnswer: {
    company: string;
    tickets: {
      _id: string;
      newMessages: number;
    }[];
  }[];
}
