const INDEX = "/register";
// const INDEX = "/register-qbcsegasdflkjewroisjfas;lk";
const SIGN_IN = `${INDEX}/sign-in`;
const SIGN_UP = {
  pathname: `${INDEX}/sign-up/:key`,
  create: (key: string) => `${INDEX}/sign-up/${key}`,
};
const RESEST_PASSWORD_REQUEST = `${INDEX}/reset-password-request`;

const CHANGE_PASSWORD = `${INDEX}/change-password/:key`;

const REGISTER_LINKS = {
  INDEX,
  SIGN_IN,
  SIGN_UP,
  RESEST_PASSWORD_REQUEST,
  CHANGE_PASSWORD,
};
export default REGISTER_LINKS;
