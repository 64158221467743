import { Suspense, lazy } from "react";
import "../global.css";
import { Navigate, createBrowserRouter } from "react-router-dom";

import ErrorPage from "pages/error-page";

import SOLUTIONS_LINKS from "utils/constants/links/solutions";
import PRODUCTS_LINKS from "utils/constants/links/products";
import ABOUT_US_LINKS from "utils/constants/links/about-us";
import CONTACT_LINKS from "utils/constants/links/contact";
import ORDER_LINKS from "utils/constants/links/order-page";
import MADE_IN_CANADA_LINKS from "utils/constants/links/made-in-canada";
import DATA_REPORT_LINKS from "utils/constants/links/data-report";
import ADMIN_PANEL_LINKS from "utils/constants/links/admin-panel";
import REGISTER_LINKS from "utils/constants/links/register";
import DASHBOARD_LINKS from "utils/constants/links/dashboard";

import RootLayout from "pages/RootLayout";
import Loading from "components/core/Loading";
import { Iuser } from "types/Iuser";
import APPLICATION_LINKS from "utils/constants/links/application";
import HELP_LINKS from "utils/constants/links/help";

const MemberPage = lazy(() => import("pages/dashboard/manager/members/member"));
const PublicLayout = lazy(() => import("pages/layout"));
const SignInPage = lazy(() => import("pages/register/sign-in"));
const SignUpPage = lazy(() => import("pages/register/sign-up"));
const RegisterLayout = lazy(() => import("pages/register/layout"));
const LandingPage = lazy(() => import("pages/landing"));

const AdminCompaniesPage = lazy(
  () => import("pages/admin-panel/admin-companies")
);

const AdminCompanyPage = lazy(() => import("pages/admin-panel/admin-company"));
const AdminCompanyMembersPage = lazy(
  () => import("pages/admin-panel/admin-company-member")
);
const AdminCompanyInvitationsPage = lazy(
  () => import("pages/admin-panel/admin-company-invitations")
);
const AdminCompanyInfoPage = lazy(
  () => import("pages/admin-panel/admin-company-info")
);
const AdminTicketsPage = lazy(() => import("pages/admin-panel/admin-tickets"));
const AdminTicketsCompanyPage = lazy(
  () => import("pages/admin-panel/admin-tickets-by-company")
);
const AdminTicketPage = lazy(() => import("pages/admin-panel/admin-ticket"));

const AdminSettings = lazy(() => import("pages/admin-panel/admin-settings"));
const AdminSettingsBatteryEstimationsPage = lazy(
  () => import("pages/admin-panel/admin-settings-battery-estimations")
);
const AdminSettingsActionsIntervals = lazy(
  () => import("pages/admin-panel/admin-settings-actions-intervals")
);
const AdminLayout = lazy(() => import("pages/admin-panel/layout"));
const AdminIndex = lazy(() => import("pages/admin-panel/index"));
const AdminReportAccess = lazy(
  () => import("pages/admin-panel/admin-report-access")
);
const AdminUsersPage = lazy(() => import("pages/admin-panel/admin-users"));
const AdminUserPage = lazy(() => import("pages/admin-panel/admin-user"));

const AdminUserEditPage = lazy(
  () => import("pages/admin-panel/admin-user-edit")
);
const AdminUserDeveloperSettingAccessPage = lazy(
  () => import("pages/admin-panel/admin-user-edit-developer-settings-access")
);
const AdminUserDeveloperSettingDisplayPage = lazy(
  () => import("pages/admin-panel/admin-user-edit-developer-settings-display")
);

const AdminInitiateTagsPage = lazy(
  () => import("pages/admin-panel/admin-initiate-tags")
);

const AdminTagPage = lazy(() => import("pages/admin-panel/admin-tag"));

const AdminInitiateTagsImports = lazy(
  () => import("pages/admin-panel/admin-initiate-tags-imports")
);
const AdminTagsPage = lazy(() => import("pages/admin-panel/admin-tags"));
const AdminMovedTagsPage = lazy(
  () => import("pages/admin-panel/admin-moved-tags")
);

const AdminImportHardwareAndSearchSetting = lazy(
  () => import("pages/admin-panel/admin-import-hardware-and-search-setting")
);
const AdminImportSearchModelAndRssiSetting = lazy(
  () => import("pages/admin-panel/admin-import-search-model-and-rrsi-setting")
);

const DashboardLayout = lazy(() => import("pages/dashboard/layout"));
const ManagerDashboardIndex = lazy(
  () => import("pages/dashboard/manager/general-report/index")
);

const ManagerGeneralReportDetails = lazy(
  () => import("pages/dashboard/manager/general-report/general-report-details")
);

const MembersPage = lazy(() => import("pages/dashboard/manager/members"));
const InvitationsPage = lazy(
  () => import("pages/dashboard/manager/invitations")
);

const InvitationPage = lazy(
  () => import("pages/dashboard/manager/invitations/invitation")
);

const ChangePasswordPage = lazy(() => import("pages/register/change-password"));
const RequestChangePasswordPage = lazy(
  () => import("pages/register/request-change-password")
);
const ProjectsPage = lazy(() => import("pages/dashboard/manager/projects"));
const ProjectPage = lazy(
  () => import("pages/dashboard/manager/projects/[projectId]")
);
const ProjectTagsPage = lazy(
  () => import("pages/dashboard/manager/projects/[projectId]/tags")
);

const EmployeeDashboardIndex = lazy(() => import("pages/dashboard/employee"));

const MangerNotificationsPage = lazy(
  () => import("pages/dashboard/manager/notifications")
);
const TagNotificationPage = lazy(
  () => import("pages/dashboard/manager/notifications/tag")
);

const EmployeeReportsPage = lazy(
  () => import("pages/dashboard/manager/notifications")
);

const ReportSetting = lazy(
  () => import("pages/dashboard/manager/notifications/setting")
);

const TagsPage = lazy(() => import("pages/dashboard/manager/tags"));
const TagPage = lazy(() => import("pages/dashboard/manager/tags/tag"));

const MovedTagsPage = lazy(() => import("pages/dashboard/manager/moved-tags"));

const SupportPage = lazy(() => import("pages/dashboard/manager/support"));
const TicketsPage = lazy(
  () => import("pages/dashboard/manager/support/tickets")
);

const TicketPage = lazy(
  () => import("pages/dashboard/manager/support/tickets/[ticketId]")
);

//
const HelpPage = lazy(() => import("pages/help"));
const HelpLayout = lazy(() => import("pages/help/layout"));
const HelpWebGeneralReportPage = lazy(
  () => import("pages/help/pages/website/HelpGeneralReport")
);

const HelpWebNotifications = lazy(
  () => import("pages/help/pages/website/HelpNotifications")
);

const HelpWebInvitations = lazy(
  () => import("pages/help/pages/website/HelpInvitations")
);

const HelpWebProjects = lazy(
  () => import("pages/help/pages/website/HelpProjects")
);

const HelpWebMemberRegistration = lazy(
  () => import("pages/help/pages/website/HelpMemberRegistration")
);

const HelpWebPasswordSetup = lazy(
  () => import("pages/help/pages/website/HelpPasswordSetup")
);

const HelpWebEmailRegistration = lazy(
  () => import("pages/help/pages/website/HelpEmailRegistration")
);

const HelpWebTags = lazy(() => import("pages/help/pages/website/HelpTags"));

const HelpWebSupport = lazy(
  () => import("pages/help/pages/website/HelpSupport")
);
const HelpWebMembers = lazy(
  () => import("pages/help/pages/website/HelpWebMembers")
);
// android

const HelpAndroidAddArea = lazy(
  () => import("pages/help/pages/android/HelpAndroidAddArea")
);
const HelpAndroidAddProject = lazy(
  () => import("pages/help/pages/android/HelpAndroidAddProject")
);
const HelpAndroidDeleteTag = lazy(
  () => import("pages/help/pages/android/HelpAndroidDeleteTag")
);
const HelpAndroidEditTag = lazy(
  () => import("pages/help/pages/android/HelpAndroidEditTag")
);
const HelpAndroidHardwareConnectionSettings = lazy(
  () => import("pages/help/pages/android/HelpAndroidHardwareConnectionSettings")
);
const HelpAndroidIntroduction = lazy(
  () => import("pages/help/pages/android/HelpAndroidIntroduction")
);
const HelpAndroidNotificationReportFilter = lazy(
  () => import("pages/help/pages/android/HelpAndroidNotificationReportFilter")
);
const HelpAndroidProfile = lazy(
  () => import("pages/help/pages/android/HelpAndroidProfile")
);
const HelpAndroidReassignTag = lazy(
  () => import("pages/help/pages/android/HelpAndroidReassignTag")
);
const HelpAndroidReplaceTag = lazy(
  () => import("pages/help/pages/android/HelpAndroidReplaceTag")
);
const HelpAndroidSearchType = lazy(
  () => import("pages/help/pages/android/HelpAndroidSearchType")
);
const HelpAndroidSeek = lazy(
  () => import("pages/help/pages/android/HelpAndroidSeek")
);
const HelpAndroidSetBatteryAlert = lazy(
  () => import("pages/help/pages/android/HelpAndroidSetBatteryAlert")
);
const HelpAndroidTag = lazy(
  () => import("pages/help/pages/android/HelpAndroidTag")
);
const HelpAndroidTagSetting = lazy(
  () => import("pages/help/pages/android/HelpAndroidTagSetting")
);
const HelpAndroidTagStatus = lazy(
  () => import("pages/help/pages/android/HelpAndroidTagStatus")
);

const HelpAndroidSearchTML = lazy(
  () => import("pages/help/pages/android/HelpAndroidSearchTLM")
);

const HelpAndroidDeveloperMode = lazy(
  () => import("pages/help/pages/android/HelpAndroidDeveloperMode")
);

const HelpAndroidImportSettings = lazy(
  () => import("pages/help/pages/android/HelpAndroidImportSettings")
);

const HelpAndroidSearchModel = lazy(
  () => import("pages/help/pages/android/HelpAndroidSearchModel")
);

// const  = lazy(
//   () => import("pages/help/pages/mobile/android")
// );

//
//
//

const ProductsPage = lazy(() => import("pages/products"));
const ProductPage = lazy(() => import("pages/product"));
const AboutUsPage = lazy(() => import("pages/about-us"));
const ContactPage = lazy(() => import("pages/contact"));
const OrderPage = lazy(() => import("pages/order"));
const MadeInCanadaPage = lazy(() => import("pages/made-in-canada"));
const SoultionsPage = lazy(() => import("pages/solutions"));
const DataReportPage = lazy(() => import("pages/data-report"));
const ApplicationPage = lazy(() => import("pages/application"));
const TestPage = lazy(() => import("pages/test"));

interface Ioptions {
  user?: Iuser;
  token?: string;
  role: "admin" | "user";
  replace?: string;
}

const premissionHandler = (
  Component: any,
  { user, token, role, replace = "/" }: Ioptions
) => {
  if (!token) {
    return <Navigate to={replace} replace={true} />;
  }
  if (!user?._id) {
    return <Loading />;
  }
  if (user._id && user?.roles?.includes(role)) {
    return <Component />;
  }
  return <></>;
};
interface Props {
  user?: Iuser;
  token?: string;
}
const roleHandler = ({
  Manager,
  Employee,
  user,
}: {
  Manager: any;
  Employee: any;
  user?: Iuser;
}) => {
  if (user?._id) {
    if (user?.companyMember?.isManager) {
      return (
        <Suspense fallback={<Loading />}>
          <Manager />
        </Suspense>
      );
    } else {
      if (Employee) {
        return (
          <Suspense fallback={<Loading />}>
            <Employee />
          </Suspense>
        );
      }
    }
    return <>...</>;
  }

  return <>...</>;
};

const useRouter = ({ user, token }: Props) => {
  // let router;
  let router = createBrowserRouter([
    {
      // root
      path: "/",
      element: <RootLayout />,
      errorElement: (
        <Suspense fallback={<Loading />}>
          <ErrorPage />
        </Suspense>
      ),
      children: [
        // public layout
        {
          path: "/",
          element: (
            <Suspense fallback={<Loading />}>
              <PublicLayout />
            </Suspense>
          ),
          errorElement: (
            <Suspense fallback={<Loading />}>
              <ErrorPage />
            </Suspense>
          ),
          children: [
            {
              path: "/test",
              element: (
                <Suspense fallback={<Loading />}>
                  <TestPage />
                </Suspense>
              ),
            },
            {
              path: "/",
              element: (
                <Suspense fallback={<Loading />}>
                  <LandingPage />
                </Suspense>
              ),
            },
            {
              path: ABOUT_US_LINKS.INDEX,
              element: (
                <Suspense fallback={<Loading />}>
                  <AboutUsPage />
                </Suspense>
              ),
            },
            {
              path: ORDER_LINKS.INDEX,
              element: (
                <Suspense fallback={<Loading />}>
                  <OrderPage />
                </Suspense>
              ),
            },
            {
              path: MADE_IN_CANADA_LINKS.INDEX,
              element: (
                <Suspense fallback={<Loading />}>
                  <MadeInCanadaPage />
                </Suspense>
              ),
            },
            {
              path: DATA_REPORT_LINKS.INDEX,
              element: (
                <Suspense fallback={<Loading />}>
                  <DataReportPage />
                </Suspense>
              ),
            },
            {
              path: APPLICATION_LINKS.INDEX,
              element: (
                <Suspense fallback={<Loading />}>
                  <ApplicationPage />
                </Suspense>
              ),
            },

            {
              path: CONTACT_LINKS.INDEX,
              element: (
                <Suspense fallback={<Loading />}>
                  <ContactPage />
                </Suspense>
              ),
            },
            {
              path: SOLUTIONS_LINKS.SOLUTIONS.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <SoultionsPage />
                </Suspense>
              ),
            },
            {
              path: PRODUCTS_LINKS.INDEX,
              element: (
                <Suspense fallback={<Loading />}>
                  <ProductsPage />
                </Suspense>
              ),
            },
            {
              path: PRODUCTS_LINKS.PRODUCTS_PRODUCT.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <ProductPage />
                </Suspense>
              ),
            },
            {
              path: REGISTER_LINKS.INDEX,
              element: (
                <Suspense fallback={<Loading />}>
                  <RegisterLayout />
                </Suspense>
              ),
              // element: token ? (
              //   <Navigate to={DASHBOARD_LINKS.INDEX} replace={true} />
              // ) : (
              //   <RegisterLayout />
              // ),
              children: [
                {
                  path: REGISTER_LINKS.INDEX,
                  element: token ? (
                    user?.roles?.includes("admin") ? (
                      <Navigate to={ADMIN_PANEL_LINKS.INDEX} replace={true} />
                    ) : (
                      <Navigate to={DASHBOARD_LINKS.INDEX} replace={true} />
                    )
                  ) : (
                    <Suspense fallback={<Loading />}>
                      <SignInPage />
                    </Suspense>
                  ),
                },
                {
                  path: REGISTER_LINKS.SIGN_IN,
                  element: <SignInPage />,
                },
                {
                  path: REGISTER_LINKS.SIGN_UP.pathname,
                  element: token ? (
                    <Navigate to={DASHBOARD_LINKS.INDEX} replace={true} />
                  ) : (
                    <Suspense fallback={<Loading />}>
                      <SignUpPage />
                    </Suspense>
                  ),
                },
                {
                  path: REGISTER_LINKS.RESEST_PASSWORD_REQUEST,
                  element: (
                    <Suspense fallback={<Loading />}>
                      <RequestChangePasswordPage />
                    </Suspense>
                  ),
                },
                {
                  path: REGISTER_LINKS.CHANGE_PASSWORD,
                  element: (
                    <Suspense fallback={<Loading />}>
                      <ChangePasswordPage />
                    </Suspense>
                  ),
                },
              ],
            },
          ],
        },

        /*
        
        
        
         admin
        
        
        
        
        */
        {
          path: ADMIN_PANEL_LINKS.INDEX,
          element: premissionHandler(AdminLayout, {
            user,
            token,
            role: "admin",
          }),

          errorElement: (
            <Suspense fallback={<Loading />}>
              <ErrorPage />
            </Suspense>
          ),
          id: "admin-panel",
          // loader: rootLoader,
          children: [
            {
              path: ADMIN_PANEL_LINKS.INDEX,
              element: <AdminIndex />,
            },
            {
              path: ADMIN_PANEL_LINKS.REPORT_ACCESS,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminReportAccess />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.COMPANIES,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminCompaniesPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.COMPANY.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminCompanyPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.COMPANY_INFO.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminCompanyInfoPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.COMPANY_MEMBERS.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminCompanyMembersPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.COMPANY_INVITATIONS.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminCompanyInvitationsPage />
                </Suspense>
              ),
            },

            {
              path: ADMIN_PANEL_LINKS.TICKETS,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminTicketsPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.TICKETS_COMPANY.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminTicketsCompanyPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.TICKET.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminTicketPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.USERS,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminUsersPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.USER.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminUserPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.USERS_EDIT.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminUserEditPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.USERS_EDIT_DEV_SETTING_ACCESS.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminUserDeveloperSettingAccessPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.USERS_EDIT_DEV_SETTING_DISPLAY.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminUserDeveloperSettingDisplayPage />
                </Suspense>
              ),
            },
            //
            {
              path: ADMIN_PANEL_LINKS.SETTINGS,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminSettings />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.SETTINGS_BATTERY_ESTIMATIONS,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminSettingsBatteryEstimationsPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.SETTINGS_ACTIONS_INTERVALS,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminSettingsActionsIntervals />
                </Suspense>
              ),
            },

            {
              path: ADMIN_PANEL_LINKS.INITIATE_TAGS,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminInitiateTagsPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.INITIATE_TAGS_IMPORTS,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminInitiateTagsImports />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.TAGS,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminTagsPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.TAG.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminTagPage />
                </Suspense>
              ),
            },

            {
              path: ADMIN_PANEL_LINKS.MOVED_TAGS,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminMovedTagsPage />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS.COMPANY_IMPORT_HARDWARE_AND_SEARCH_SETTING
                .pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminImportHardwareAndSearchSetting />
                </Suspense>
              ),
            },
            {
              path: ADMIN_PANEL_LINKS
                .COMPANY_IMPORT_SEARCH_MODEL_AND_RSSI_SETTING.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <AdminImportSearchModelAndRssiSetting />
                </Suspense>
              ),
            },
          ],
        },
        /*
        
        
        
         admin
        
        
        
        
        */

        /*



          dashboard



          
        */

        {
          path: DASHBOARD_LINKS.INDEX,

          element: premissionHandler(DashboardLayout, {
            user,
            token,
            role: "user",
          }),
          errorElement: (
            <Suspense fallback={<Loading />}>
              <ErrorPage />
            </Suspense>
          ),
          id: "dashboard",
          // loader: rootLoader,
          children: [
            {
              path: DASHBOARD_LINKS.INDEX,
              element: roleHandler({
                Manager: ManagerDashboardIndex,
                Employee: ManagerDashboardIndex,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.GENERAL_REPORT_DETAILS,
              element: roleHandler({
                Manager: ManagerGeneralReportDetails,
                Employee: ManagerGeneralReportDetails,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.INVITATIONS,
              element: roleHandler({
                Manager: InvitationsPage,
                Employee: null,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.INVITATION.pathname,
              element: roleHandler({
                Manager: InvitationPage,
                Employee: null,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.MEMBERS,
              element: roleHandler({
                Manager: MembersPage,
                Employee: null,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.MEMBER.pathname,
              element: roleHandler({
                Manager: MemberPage,
                Employee: null,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.PROJECTS,
              element: roleHandler({
                Manager: ProjectsPage,
                Employee: null,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.PROJECTS_PROJECT.pathname,
              element: roleHandler({
                Manager: ProjectPage,
                Employee: null,
                user,
              }),
            },

            {
              path: DASHBOARD_LINKS.PROJECTS_PROJECT_TAGS.pathname,
              element: roleHandler({
                Manager: ProjectTagsPage,
                Employee: null,
                user,
              }),
            },

            {
              path: DASHBOARD_LINKS.NOTIFICATIONS,
              element: roleHandler({
                Manager: MangerNotificationsPage,
                Employee: EmployeeReportsPage,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.NOTIFICATIONS_SETTING,
              element: roleHandler({
                Manager: ReportSetting,
                Employee: null,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.TAGS,
              element: roleHandler({
                Manager: TagsPage,
                Employee: TagsPage,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.TAG.pathname,
              element: roleHandler({
                Manager: TagPage,
                Employee: TagPage,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.NOTIFICATIONS_TAG.pathname,
              element: roleHandler({
                Manager: TagNotificationPage,
                Employee: TagNotificationPage,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.MOVED_TAGS,
              element: roleHandler({
                Manager: MovedTagsPage,
                Employee: null,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.SUPPORT,
              element: roleHandler({
                Manager: SupportPage,
                Employee: null,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.SUPPORT_TICKETS,
              element: roleHandler({
                Manager: TicketsPage,
                Employee: null,
                user,
              }),
            },
            {
              path: DASHBOARD_LINKS.SUPPORT_TICKETS_TICKET.pathname,
              element: (
                <Suspense fallback={<Loading />}>
                  <TicketPage />
                </Suspense>
              ),
            },
          ],
        },

        /*



          dashboard



          
        */

        {
          path: HELP_LINKS.INDEX,
          element: user?._id ? (
            <HelpLayout />
          ) : (
            <Navigate to={"/"} replace={true} />
          ),
          errorElement: (
            <Suspense fallback={<Loading />}>
              <ErrorPage />
            </Suspense>
          ),
          id: "help",
          children: [
            {
              path: HELP_LINKS.HELP_WEB_GENERAL_REPORT,
              element: <HelpWebGeneralReportPage />,
            },
            {
              path: HELP_LINKS.HELP_WEB_NOTIFICATIONS,
              element: <HelpWebNotifications />,
            },
            {
              path: HELP_LINKS.HELP_WEB_INVITATIONS,
              element: <HelpWebInvitations />,
            },
            {
              path: HELP_LINKS.HELP_WEB_PROJECTS,
              element: <HelpWebProjects />,
            },
            {
              path: HELP_LINKS.HELP_WEB_MEMBER_REGISTRATION,
              element: <HelpWebMemberRegistration />,
            },
            {
              path: HELP_LINKS.HELP_WEB_EMAIL_REGISTRATION,
              element: <HelpWebEmailRegistration />,
            },
            {
              path: HELP_LINKS.HELP_WEB_PASSWORD_SETUP,
              element: <HelpWebPasswordSetup />,
            },

            {
              path: HELP_LINKS.HELP_WEB_TAGS,
              element: <HelpWebTags />,
            },
            {
              path: HELP_LINKS.HELP_WEB_SUPPORT,
              element: <HelpWebSupport />,
            },
            {
              path: HELP_LINKS.HELP_WEB_MEMBERS,
              element: <HelpWebMembers />,
            },
            // android
            {
              path: HELP_LINKS.HELP_ANDROID_ADD_AREA,
              element: <HelpAndroidAddArea />,
            },

            {
              path: HELP_LINKS.HELP_ANDROID_ADD_PROJECT,
              element: <HelpAndroidAddProject />,
            },

            {
              path: HELP_LINKS.HELP_ANDROID_DELETE_TAG,
              element: <HelpAndroidDeleteTag />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_EDIT_TAG,
              element: <HelpAndroidEditTag />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_HARDWARE_CONNECTION_SETTINGS,
              element: <HelpAndroidHardwareConnectionSettings />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_INTRODUCTION,
              element: <HelpAndroidIntroduction />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_NOTIFICATION_REPORT_FILTER,
              element: <HelpAndroidNotificationReportFilter />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_PROFILE,
              element: <HelpAndroidProfile />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_REASSIGN_TAG,
              element: <HelpAndroidReassignTag />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_REPLACE_TAG,
              element: <HelpAndroidReplaceTag />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_SEARCH_TYPE,
              element: <HelpAndroidSearchType />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_SEEK,
              element: <HelpAndroidSeek />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_SET_BATTERY_ALERT,
              element: <HelpAndroidSetBatteryAlert />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_TAG,
              element: <HelpAndroidTag />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_ADD_AREA,
              element: <HelpAndroidAddArea />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_TAG_SETTING,
              element: <HelpAndroidTagSetting />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_TAG_STATUS,
              element: <HelpAndroidTagStatus />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_SEARCH_TLM,
              element: <HelpAndroidSearchTML />,
            },
            {
              path: HELP_LINKS.HELP_ANDROID_DEVELOPER_MODE,
              element: <HelpAndroidDeveloperMode />,
            },

            {
              path: HELP_LINKS.HELP_ANDROID_IMPORT_SETTINGS,
              element: <HelpAndroidImportSettings />,
            },

            {
              path: HELP_LINKS.HELP_ANDROID_SEARCH_MODEL,
              element: <HelpAndroidSearchModel />,
            },
          ],
        },
      ],
    },
  ]);

  return router;
};

export default useRouter;
