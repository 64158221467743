import { Iuser } from "types/Iuser";
import useTicketsStatics from "./useTicketsStatics";
import { useDispatch } from "react-redux";
import { getReportSettings } from "hooks/get/client-get-report-settings";
import { prepareReportSettings } from "utils/prepareReportSettings";
import { updateReportSettings } from "store/slices/profile";

const useInitialData = () => {
  const dispatch = useDispatch();
  const { getData: setupTicketStatics } = useTicketsStatics();

  const setupReportSettings = () => {
    getReportSettings().then((res) => {
      const newData = prepareReportSettings(res.data);
      dispatch(updateReportSettings(newData));
    });
  };

  const setupAll = (user?: Iuser) => {
    if (!user?.roles) return;
    if (user?.roles?.includes("admin")) {
      setupTicketStatics();
    } else {
      setupReportSettings();
    }
  };

  return { setupAll, setupTicketStatics, setupReportSettings };
};

export default useInitialData;
