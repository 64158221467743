const INDEX = "/admin-panel";
const USERS = `${INDEX}/users`;

const USER = {
  pathname: `${USERS}/:id`,
  create: (id: string) => `${USERS}/${id}`,
};

const USERS_EDIT = {
  pathname: `${USER.pathname}/edit`,
  create: (id: string) => `${USER.create(id)}/edit`,
};

const USERS_EDIT_DEV_SETTING_ACCESS = {
  pathname: `${USER.pathname}/dev-setting-access`,
  create: (id: string) => `${USER.create(id)}/dev-setting-access`,
};
const USERS_EDIT_DEV_SETTING_DISPLAY = {
  pathname: `${USER.pathname}/dev-setting-display`,
  create: (id: string) => `${USER.create(id)}/dev-setting-display`,
};

const COMPANIES = `${INDEX}/companies`;

const COMPANY = {
  pathname: `${COMPANIES}/:id`,
  create: (id: string) => `${COMPANIES}/${id}`,
};

const COMPANY_INFO = {
  pathname: `${COMPANY.pathname}/info`,
  create: (id: string) => `${COMPANY.create(id)}/info`,
};

const COMPANY_MEMBERS = {
  pathname: `${COMPANY.pathname}/members`,
  create: (id: string) => `${COMPANY.create(id)}/members`,
};

const COMPANY_INVITATIONS = {
  pathname: `${COMPANY.pathname}/invitations`,
  create: (id: string) => `${COMPANY.create(id)}/invitations`,
};

const COMPANY_IMPORT_HARDWARE_AND_SEARCH_SETTING = {
  pathname: `${COMPANY.pathname}/import-hardware-and-search-setting`,
  create: (id: string) =>
    `${COMPANY.create(id)}/import-hardware-and-search-setting`,
};

const COMPANY_IMPORT_SEARCH_MODEL_AND_RSSI_SETTING = {
  pathname: `${COMPANY.pathname}/import-search-model-and-rrsi-setting`,
  create: (id: string) =>
    `${COMPANY.create(id)}/import-search-model-and-rrsi-setting`,
};

const TICKETS = `${INDEX}/tickets`;
const TICKETS_COMPANY = {
  pathname: `${INDEX}/tickets/:companyId`,
  create: (companyId: string) => `${INDEX}/tickets/${companyId}`,
};
const TICKET = {
  pathname: `${TICKETS_COMPANY.pathname}/:ticketId`,
  create: (companyId: string, ticketId: string) =>
    `${TICKETS_COMPANY.create(companyId)}/${ticketId}`,
};

const SETTINGS = `${INDEX}/settings`;
const SETTINGS_BATTERY_ESTIMATIONS = `${SETTINGS}/battery-estimations`;
const SETTINGS_ACTIONS_INTERVALS = `${SETTINGS}/actions-intervals`;
const REPORT_ACCESS = `${INDEX}/report-access`;
const INITIATE_TAGS = `${INDEX}/initiate-tags`;
const INITIATE_TAGS_IMPORTS = `${INITIATE_TAGS}/imports`;
const TAGS = `${INDEX}/tags`;
const TAG = {
  create: (publicAddress: string) => `${TAGS}/${publicAddress}`,
  pathname: `${TAGS}/:publicAddress`,
};
const MOVED_TAGS = `${INDEX}/moved-tags`;

const ADMIN_PANEL_LINKS = {
  INDEX,
  USERS,
  USER,
  USERS_EDIT,
  USERS_EDIT_DEV_SETTING_ACCESS,
  USERS_EDIT_DEV_SETTING_DISPLAY,
  COMPANIES,
  COMPANY,
  COMPANY_INFO,
  COMPANY_MEMBERS,
  COMPANY_INVITATIONS,
  COMPANY_IMPORT_HARDWARE_AND_SEARCH_SETTING,
  COMPANY_IMPORT_SEARCH_MODEL_AND_RSSI_SETTING,
  REPORT_ACCESS,
  TICKETS,
  TICKETS_COMPANY,
  TICKET,
  SETTINGS,
  SETTINGS_BATTERY_ESTIMATIONS,
  SETTINGS_ACTIONS_INTERVALS,
  INITIATE_TAGS,
  INITIATE_TAGS_IMPORTS,
  TAGS,
  TAG,
  MOVED_TAGS,
};
export default ADMIN_PANEL_LINKS;
