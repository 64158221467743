import { useDispatch } from "react-redux";
import { useEffect, useMemo } from "react";
import { AxiosError } from "axios";
import { removeAxiosHeader, setupAxios } from "libs/setup-axios";
import { api } from "hooks";
import { API_BASE_URL } from "utils/constants/api-base-url";
import { updateDialog } from "store/slices/common";
import {
  DEFAULT_USER,
  SELECT_USER,
  updateReportSettings,
  updateUser,
} from "store/slices/profile";
import { me } from "hooks/get/me";
import { refreshToken as sendRefreshToken } from "hooks/post/refresh-token";
import { useTranslation } from "react-i18next";
import { getReportSettings } from "hooks/get/client-get-report-settings";
import { prepareReportSettings } from "utils/prepareReportSettings";
import useInitialData from "./useInitialData";

const logoutHandler = async () => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");

    removeAxiosHeader(api);
    updateUser(DEFAULT_USER);

    window.location.replace("/");
  } catch (error) {
    window.location.replace("/");
  }
};

const useAuth = () => {
  const { t } = useTranslation("errors");
  const { setupAll } = useInitialData();
  const user = SELECT_USER();
  const dispatch = useDispatch();

  const errorHandler = () => async (err: AxiosError<unknown, any>) => {
    let response: any = err.response;

    if (response?.status === 401) {
      // if (!refreshToken) return;
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken) {
        return logoutHandler();
      }
      return sendRefreshToken(refreshToken)
        .then((res) => {
          // newToken
          const { token, refreshToken, ...rest } = res.data;

          localStorage.setItem("token", token!);
          localStorage.setItem("refreshToken", refreshToken!);
          setupAxios({
            axiosInstance: api,
            baseUrl: API_BASE_URL,
            token: token,
            errorHandler: errorHandler(),
          });

          const newErrorConfig = { ...err.config };
          if (newErrorConfig?.headers) {
            newErrorConfig.headers.Authorization = `Bearer ${token}`;
          }
          return api(newErrorConfig);
        })
        .catch((err) => {
          logoutHandler();
        });
    } else {
      const { data, message, code }: any = response.data;

      dispatch(
        updateDialog({
          title: t("server error"),
          open: true,
          // @ts-ignore
          text: code ? t(code) : message,
          code: code,
        })
      );

      return Promise.reject(err);
    }
  };

  const token = localStorage.getItem("token") || undefined;

  useMemo(() => {
    setupAxios({
      axiosInstance: api,
      baseUrl: API_BASE_URL,
      token: token || "",
      errorHandler: errorHandler(),
    });
  }, [token]);

  const setupSettings = () => {
    getReportSettings().then((res) => {
      const newData = prepareReportSettings(res.data);
      dispatch(updateReportSettings(newData));
    });
  };

  useEffect(() => {
    if (!token) return;

    if (user._id) {
      setupAll(user);
      return;
    }

    me()
      .then((res) => {
        dispatch(updateUser({ ...res.data, token }));
      })
      .catch(console.log);
  }, [token, user._id]);

  return { token, user };
};

export default useAuth;
