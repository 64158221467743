const INDEX = "/help";

// Website
const HELP_WEB = `${INDEX}/website`;
const HELP_WEB_GENERAL_REPORT = `${HELP_WEB}/general-report`;
const HELP_WEB_NOTIFICATIONS = `${HELP_WEB}/notifications`;
const HELP_WEB_INVITATIONS = `${HELP_WEB}/invitations`;
const HELP_WEB_MEMBERS = `${HELP_WEB}/members`;
const HELP_WEB_PROJECTS = `${HELP_WEB}/projects`;
const HELP_WEB_EMAIL_REGISTRATION = `${HELP_WEB}/email-registration`;
const HELP_WEB_MEMBER_REGISTRATION = `${HELP_WEB}/member-registration`;
const HELP_WEB_PASSWORD_SETUP = `${HELP_WEB}/password-setup`;
const HELP_WEB_TAGS = `${HELP_WEB}/tags`;
const HELP_WEB_SUPPORT = `${HELP_WEB}/supports`;

// Android
const HELP_ANDROID = `${INDEX}/android`;

const HELP_ANDROID_INTRODUCTION = `${HELP_ANDROID}/introduction`;
const HELP_ANDROID_PROFILE = `${HELP_ANDROID}/profile`;
const HELP_ANDROID_TAG_STATUS = `${HELP_ANDROID}/tag-status`;
const HELP_ANDROID_NOTIFICATION_REPORT_FILTER = `${HELP_ANDROID}/notification-report-filter`;
const HELP_ANDROID_SEEK = `${HELP_ANDROID}/seek`;
const HELP_ANDROID_REPLACE_TAG = `${HELP_ANDROID}/replace-tag`;
const HELP_ANDROID_REASSIGN_TAG = `${HELP_ANDROID}/reassign-tag`;
const HELP_ANDROID_EDIT_TAG = `${HELP_ANDROID}/edit-tag`;
const HELP_ANDROID_DELETE_TAG = `${HELP_ANDROID}/delete-tag`;
const HELP_ANDROID_TAG = `${HELP_ANDROID}/tag`;
const HELP_ANDROID_TAG_SETTING = `${HELP_ANDROID}/tag-setting`;
const HELP_ANDROID_ADD_PROJECT = `${HELP_ANDROID}/add-project`;
const HELP_ANDROID_ADD_AREA = `${HELP_ANDROID}/add-area`;
const HELP_ANDROID_SET_BATTERY_ALERT = `${HELP_ANDROID}/set-battery-alert`;
const HELP_ANDROID_SEARCH_TYPE = `${HELP_ANDROID}/search-type`;
const HELP_ANDROID_HARDWARE_CONNECTION_SETTINGS = `${HELP_ANDROID}/hardware-connection-settings`;
const HELP_ANDROID_SEARCH_TLM = `${HELP_ANDROID}/search-TLM`;
const HELP_ANDROID_DEVELOPER_MODE = `${HELP_ANDROID}/developer-mode`;
const HELP_ANDROID_IMPORT_SETTINGS = `${HELP_ANDROID}/import-settings`;
const HELP_ANDROID_SEARCH_MODEL = `${HELP_ANDROID}/search-model`;
// Ios

const HELP_LINKS = {
  INDEX,
  // Website
  HELP_WEB,
  HELP_WEB_GENERAL_REPORT,
  HELP_WEB_NOTIFICATIONS,
  HELP_WEB_INVITATIONS,
  HELP_WEB_MEMBERS,
  HELP_WEB_PROJECTS,
  HELP_WEB_EMAIL_REGISTRATION,
  HELP_WEB_MEMBER_REGISTRATION,
  HELP_WEB_PASSWORD_SETUP,
  HELP_WEB_TAGS,
  HELP_WEB_SUPPORT,
  // Android
  HELP_ANDROID_INTRODUCTION,
  HELP_ANDROID_PROFILE,
  HELP_ANDROID_TAG_STATUS,
  HELP_ANDROID_NOTIFICATION_REPORT_FILTER,
  HELP_ANDROID_SEEK,
  HELP_ANDROID_REPLACE_TAG,
  HELP_ANDROID_REASSIGN_TAG,
  HELP_ANDROID_EDIT_TAG,
  HELP_ANDROID_DELETE_TAG,
  HELP_ANDROID_TAG,
  HELP_ANDROID_TAG_SETTING,
  HELP_ANDROID_ADD_PROJECT,
  HELP_ANDROID_ADD_AREA,
  HELP_ANDROID_SET_BATTERY_ALERT,
  HELP_ANDROID_SEARCH_TYPE,
  HELP_ANDROID_HARDWARE_CONNECTION_SETTINGS,
  HELP_ANDROID_SEARCH_TLM,
  HELP_ANDROID_DEVELOPER_MODE,
  HELP_ANDROID_IMPORT_SETTINGS,
  HELP_ANDROID_SEARCH_MODEL,
  // Ios
  //
};
export default HELP_LINKS;
