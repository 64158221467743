import classNames from "classnames";
import { Iclasses } from "types/Iclasses";

interface Props extends Iclasses {}
const Loading = ({ classes }: Props) => {
  return (
    <div
      className={classNames(
        "w-full h-[calc(100vh-300px)] flex justify-center items-center",
        classes
      )}
    >
      <div className="loading loading-lg text-primary" />
    </div>
  );
};

export default Loading;
