import useAuth from "components/helper/useAuth";
import { RouterProvider } from "react-router-dom";
import useRouter from "router/useRouter";
import { Suspense } from "react";

function TranslateApp() {
  const { user, token } = useAuth();
  const router = useRouter({ user, token });

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

const App = () => {
  return (
    <>
      <Suspense
        fallback={
          <div className="w-[100vw] h-[100vh] flex justify-center items-center">
            <div className="loading loading-lg text-primary" />
          </div>
        }
      >
        <TranslateApp />
      </Suspense>
    </>
  );
};

export default App;
