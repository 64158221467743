const INDEX = "/products";

const PRODUCTS_PRODUCT = {
  pathname: `${INDEX}/:id`,
  create: (id: string) => `${INDEX}/${id}`,
};

const PRODUCTS_LINKS = {
  INDEX,
  PRODUCTS_PRODUCT,
};
export default PRODUCTS_LINKS;
