import { adminGetSettings } from "hooks/get/admin-get-settings";
import { ITicketCard } from "./../admin/AdminTicketStaticsCard/index";
import {
  adminGetTicketsCountByCompany,
  ITicketCountByCompany,
} from "hooks/get/admin-get-tickets-count-by-company";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  SELECT_ADMIN_TICKET_STATICS,
  updateTicketsStatics,
} from "store/slices/admin";
import { Icompany } from "types/Icompany";

interface Props {
  start?: boolean;
}

const useTicketsStatics = (props?: Props) => {
  const { start } = props || { start: false };
  const [timer, setTimer] = useState<NodeJS.Timer>();
  // const reports  = SELECT_AMIN_
  const ticketStatics = SELECT_ADMIN_TICKET_STATICS();

  const dispatch = useDispatch();
  const getData = () => {
    adminGetTicketsCountByCompany()
      .then((res) => {
        dispatch(updateTicketsStatics(res.data));
      })
      .catch(console.log);
  };

  const stopInterval = () => {
    clearInterval(timer);
  };

  const startInterval = (intervalTime: number) => {
    stopInterval();
    setTimer(
      setInterval(() => {
        getData();
      }, intervalTime)
    );
  };

  useEffect(() => {
    if (start) {
      adminGetSettings().then((res) => {
        startInterval(res.data.intervalAdminTicket);
      });
    }
  }, [start]);

  useEffect(() => {
    return () => {
      stopInterval();
    };
  });

  return {
    ticketStatics,
    getData,
    stopInterval,
    startInterval,
  };
};

export default useTicketsStatics;

export const groupByCompanies = (
  companies: Icompany[],
  ticketStatics: ITicketCountByCompany
) => {
  let items: ITicketCard[] = [];
  companies.forEach((company) => {
    let closed =
      ticketStatics.closed.find((c) => c.company === company._id)?.count || 0;
    let inProgress =
      ticketStatics.inProgress.find((c) => c.company === company._id)?.count ||
      0;

    let waitingForAnswer = ticketStatics.waitingForAnswer.find(
      (c) => c.company === company._id
    );
    let newMessage = 0;
    if (waitingForAnswer) {
      waitingForAnswer.tickets.forEach((t) => {
        newMessage = newMessage + t.newMessages;
      });
    }
    items.push({ company, closed, inProgress, newMessage });
  });
  return items;
};
