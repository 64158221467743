import classNames from "classnames";
import React from "react";
import "./style.css";
import { Iclasses } from "types/Iclasses";
interface Props
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    Iclasses {
  children?: React.ReactNode;
  color?: "primary" | "secondary" | "black" | "white";
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  variant?: "contain" | "outline" | "text";
  fullWidth?: boolean;
}
const Btn = (props: Props) => {
  const {
    children,
    color = "primary",
    variant = "contain",
    size = "md",
    fullWidth = false,
    classes,
    ...rest
  } = props;
  return (
    <button
      {...rest}
      className={classNames(
        "Button",
        `${size}`,
        variant,
        color,
        {
          "w-full": fullWidth,
        },
        classes
      )}
    >
      {children}
    </button>
  );
};

export default Btn;
