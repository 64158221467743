import { IreportSettings } from "types/IreportSettings";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Iuser } from "types/Iuser";
import { AppState } from "store";
import { useAppSelector } from "store/hooks";

export interface ProfileStateTypes {
  user: Iuser;
  reportSettings: IreportSettings;
  newMessageCount: number;
}

export const DEFAULT_USER = {
  name: "",
  lastName: "",
  email: "",
  _id: "",
  roles: [],
  isBlocked: false,
  phone: "",
  language: "en",
  token: "",
  isMobileDeveloper: false,
};

const initialState: ProfileStateTypes = {
  user: DEFAULT_USER,
  newMessageCount: 0,
  reportSettings: {
    batteryEstimatedLifeFormula: "",
    reportSetting: {
      batteryAlertRange: { warning: 0, critical: 0 },
      iterationLost: { warning: 0, critical: 0 },
      tagExpDate: { warning: 0, critical: 0 },
      targetExpDate: { warning: 0, critical: 0 },
      targetServiceDate: { critical: 0, warning: 0 },
    },
    validDuration: 0,
    voltageToPercentageDictionary: [],
  },
};

export const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateUser: (state: ProfileStateTypes, action: PayloadAction<Iuser>) => {
      state.user = action.payload;
    },
    updateReportSettings: (
      state: ProfileStateTypes,
      action: PayloadAction<IreportSettings>
    ) => {
      state.reportSettings = action.payload;
    },
    updateNewMessageCount: (
      state: ProfileStateTypes,
      action: PayloadAction<number>
    ) => {
      state.newMessageCount = action.payload;
    },
  },
});

//
export const { updateUser, updateReportSettings, updateNewMessageCount } =
  profile.actions;
//

export const SELECT_USER = () =>
  useAppSelector((state: AppState) => state.profile.user);

export const SELECT_NOTIFICATIONS_SETTINGS = () =>
  useAppSelector((state: AppState) => state.profile.reportSettings);

export const SELECT_NEW_MESSAGE_COUNT = () =>
  useAppSelector((state: AppState) => state.profile.newMessageCount);

export default profile.reducer;
