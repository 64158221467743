const INDEX = "/solutions";

const SOLUTIONS = {
  pathname: `${INDEX}/:id`,
  create: (id: string) => `${INDEX}/${id}`,
};

const SOLUTIONS_LINKS = {
  SOLUTIONS,
};
export default SOLUTIONS_LINKS;
