import { IreportSettings } from "types/IreportSettings";

export const prepareReportSettings = (data: IreportSettings) => {
  const newData = { ...data };
  let voltageToPercentageDictionary: any[] = [];
  newData.voltageToPercentageDictionary.forEach((v) => {
    voltageToPercentageDictionary.push({
      value: `${v.value}%`,
      voltage: v.voltage,
    });
  });

  newData.voltageToPercentageDictionary = voltageToPercentageDictionary;
  return newData;
};
