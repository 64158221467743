import "./style.css";
import { memo, useCallback, useEffect } from "react";
import { Modal } from "react-daisyui";
import { useDispatch } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  DEFAULT_DIALOG,
  SELECT_DIALOG,
  updateDialog,
} from "store/slices/common";
import Btn from "components/core/Btn";
import { removeAxiosHeader } from "libs/setup-axios";
import { api } from "hooks";
import { DEFAULT_USER, updateUser } from "store/slices/profile";
import { ToastContainer } from "react-toastify";
import {
  Danger,
  Happyemoji,
  InfoCircle,
  LocationCross,
  MessageNotif,
  ProfileDelete,
  RefreshSquare,
  SearchZoomIn,
  ShieldCross,
  SmsNotification,
} from "iconsax-react";

const logoutHandler = async () => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");

    removeAxiosHeader(api);
    updateUser(DEFAULT_USER);
    window.location.replace("/");
  } catch (error) {
    window.location.replace("/");
  }
};

const RootLayout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { open, title, text, actions, status, code } = SELECT_DIALOG();
  const closeDialog = useCallback(() => {
    dispatch(updateDialog(DEFAULT_DIALOG));
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  const key = localStorage.getItem("-key-");
  // Toastify__toast-container Toastify__toast-container--top-right
  return (
    <>
      {/* {key === "9000" ? ( */}
      <>
        <ToastContainer
          progressStyle={{
            // height: 0,
            height: 0,
          }}
          theme="dark"
        ></ToastContainer>
        <Outlet />
        <Modal
          open={open}
          onClickBackdrop={closeDialog}
          className="rounded-2xl text-center max-w-sm md:max-w-lg card relative !p-6"
        >
          <>
            <Modal.Header className="font-normal text-t2 md:text-t1 text-white">
              <div className="w-full flex justify-center">
                <IconHandler code={code} />
              </div>
              <h3 className="text-primary font-bold typo-h3 mt-6 first-letter:first-letter:capitalize">
                {title}
              </h3>
            </Modal.Header>

            <Modal.Body className="mt-2 typo-b2">
              {text}
              {/* {code ? (
                  <div className="text-b3 my-4 text-left absolute bottom-0 left-4 text-secondary">
                    #{code}
                  </div>
                ) : null} */}
            </Modal.Body>

            <Modal.Actions className={"mt-8"}>
              {actions?.map((action, index) =>
                action.type === "LINK" && action.link ? (
                  <Link
                    to={action.link}
                    key={index}
                    onClick={closeDialog}
                    className={`m-auto w-[200px] no-underline text-${action.color} btn btn-primary btn-${action.variant}`}
                  >
                    {action.title}
                  </Link>
                ) : (
                  <Btn
                    key={index}
                    onClick={closeDialog}
                    classes={`m-auto w-[200px] no-underline`}
                  >
                    {action.title}
                  </Btn>
                )
              )}
            </Modal.Actions>
          </>
        </Modal>
      </>
      {/* ) : (
        <GetPermission />
      )} */}
    </>
  );
};

export default memo(RootLayout);

interface IiconHandler {
  code?: string;
}
const IconHandler = ({ code }: IiconHandler) => {
  if (!code) return <Danger size={70} variant="Bold" />;
  if (code === "4001") {
    return <MessageNotif size={70} variant="Bold" />;
  }
  if (code === "4002") {
    return <SmsNotification size={70} variant="Bold" />;
  }
  if (
    code === "4003" ||
    code === "4031" ||
    code === "4032" ||
    code === "4042"
  ) {
    return <ProfileDelete size={70} variant="Bold" />;
  }

  if (code === "4041" || code === "4045") {
    return <SearchZoomIn size={70} variant="Bold" />;
  }

  if (code === "4044" || code === "4094") {
    return <LocationCross size={70} variant="Bold" />;
  }
  if (code === "4221" || code === "4091" || code === "4222") {
    return <InfoCircle size={70} variant="Bold" />;
  }
  if (code === "4223" || code === "4224") {
    return <RefreshSquare size={70} variant="Bold" />;
  }
  if (code === "4033" || code === "4034") {
    return <CompanyBlocked />;
  }
  if (code === "4035") {
    return <Happyemoji size={70} variant="Bold" />;
  }

  if (code === "4092") {
    return <MessageNotif size={70} variant="Bold" />;
  }
  if (code === "4093") {
    return <ShieldCross size={70} variant="Bold" />;
  }
  if (code === "5001") {
    return <Danger size={70} variant="Bold" />;
  }

  return <Danger size={70} variant="Bold" />;
};

const CompanyBlocked = () => (
  <svg
    width={70}
    height={70}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.5474 35.4165H38.3307C39.0141 35.4165 39.5807 35.9832 39.5807 36.6665C39.5807 37.3498 39.0141 37.9165 38.3307 37.9165H33.3807C33.3474 37.9165 33.3307 37.9332 33.2974 37.9332C33.2641 37.9332 33.2474 37.9165 33.2141 37.9165H1.66406C0.980729 37.9165 0.414062 37.3498 0.414062 36.6665C0.414062 35.9832 0.980729 35.4165 1.66406 35.4165H32.0307H34.5474Z"
      fill="#dcdcdc"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2205 5C21.6856 4.99998 22 5.53469 22 5.99981C22 9.31352 24.6863 11.9998 28 11.9998H29C29.564 11.9998 30.3333 12.3861 30.3333 12.9502V35.6669C30.3333 36.2192 29.8856 36.6669 29.3333 36.6669H22.5C21.9477 36.6669 21.5 36.2192 21.5 35.6669V30.4169C21.5 29.7169 20.9333 29.1669 20.25 29.1669C19.5667 29.1669 19 29.7336 19 30.4169V35.6669C19 36.2192 18.5523 36.6669 18 36.6669H11C10.4477 36.6669 10 36.2192 10 35.6669V10.0336C10 6.6836 11.6667 5.00026 14.9833 5.00026L21.2205 5ZM16.1667 21.2503H24.4167C25.1 21.2503 25.6667 20.6836 25.6667 20.0003C25.6667 19.3169 25.1 18.7503 24.4167 18.7503H16.1667C15.4833 18.7503 14.9167 19.3169 14.9167 20.0003C14.9167 20.6836 15.4833 21.2503 16.1667 21.2503ZM16.1667 15.0003H24.4167C25.1 15.0003 25.6667 14.4336 25.6667 13.7503C25.6667 13.0669 25.1 12.5003 24.4167 12.5003H16.1667C15.4833 12.5003 14.9167 13.0669 14.9167 13.7503C14.9167 14.4336 15.4833 15.0003 16.1667 15.0003Z"
      fill="#dcdcdc"
    />
    <path
      d="M28.75 1C26.1205 1 24 3.12054 24 5.75001C24 8.37947 26.1205 10.5 28.75 10.5C31.3795 10.5 33.5 8.37947 33.5 5.75001C33.5 3.12054 31.3795 1 28.75 1ZM31.2946 7.36161C31.5491 7.61607 31.5491 8.04018 31.2946 8.37946C31.0402 8.63393 30.6161 8.63393 30.2768 8.37946L28.6652 6.76786L27.0536 8.37946C26.7991 8.63393 26.375 8.63393 26.0357 8.37946C25.7812 8.125 25.7812 7.70089 26.0357 7.36161L27.6473 5.75001L26.0357 4.13839C25.7812 3.88393 25.7812 3.45982 26.0357 3.12054C26.2902 2.86607 26.7143 2.86607 27.0536 3.12054L28.6652 4.73214L30.2768 3.12054C30.5312 2.86607 30.9554 2.86607 31.2946 3.12054C31.5491 3.375 31.5491 3.79911 31.2946 4.13839L29.683 5.75001L31.2946 7.36161Z"
      fill="#dcdcdc"
    />
  </svg>
);
