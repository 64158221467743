import { configureStore, ThunkAction, combineReducers } from "@reduxjs/toolkit";

import { Action } from "redux";

import common from "./slices/common";
import profile from "./slices/profile";
import admin from "./slices/admin";

const combineReducer = combineReducers({
  common,
  profile,
  admin,
});

export const makeStore = () => {
  const store = configureStore({
    reducer: combineReducer,
    devTools: true,
  });

  return store;
};
// typeof makeStore.dispatch
export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore["dispatch"];
export type AppState = ReturnType<AppStore["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
export const store = makeStore();
