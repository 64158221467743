import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "..";
import { useAppSelector } from "../hooks";
import { Ibreadcrumb } from "components/core/Breadcrumbs";

export interface IdialogAction {
  type: "CLOSE" | "TRY_AGAIN" | "LOGOUT" | "LINK";
  variant?: "link" | "outline";
  title: string;
  link?: string;
  color?:
    | "primary"
    | "secondary"
    | "accent"
    | "ghost"
    | "info"
    | "success"
    | "warning"
    | "error";
}
export interface Idialog {
  open: boolean;
  title?: string;
  text?: string;
  actions?: IdialogAction[];
  status?: "blocked" | "error" | "message";
  code?: string;
}

export const DEFAULT_DIALOG: Idialog = {
  open: false,
  title: "",
  text: "",
  actions: [{ type: "CLOSE", title: "Ok", variant: "link", color: "info" }],
  status: "message",
};

export interface CommonStateTypes {
  drawer: boolean;
  scroll: number;
  size: { width: number; height: number };
  dialog: Idialog;
  breadcrumbs: Ibreadcrumb[];
}

const initialState: CommonStateTypes = {
  drawer: false,
  scroll: 0,
  size: { width: 0, height: 0 },
  dialog: {
    open: false,
    title: "",
    text: "",
    actions: [{ type: "CLOSE", title: "Ok", variant: "link", color: "info" }],
  },
  breadcrumbs: [],
};

export const common = createSlice({
  name: "common",
  initialState,
  reducers: {
    updateScroll: (state: CommonStateTypes, action: PayloadAction<number>) => {
      state.scroll = action.payload;
    },
    updateSize: (
      state: CommonStateTypes,
      action: PayloadAction<{ width: number; height: number }>
    ) => {
      state.size = action.payload;
    },
    updateDialog: (state: CommonStateTypes, action: PayloadAction<Idialog>) => {
      state.dialog = { ...state.dialog, ...action.payload };
    },
    updateDrawer: (state: CommonStateTypes, action: PayloadAction<boolean>) => {
      state.drawer = action.payload;
    },
    toggleDrawer: (state: CommonStateTypes, action: PayloadAction) => {
      state.drawer = !state.drawer;
    },
    updateBreadcrumbs: (
      state: CommonStateTypes,
      action: PayloadAction<Ibreadcrumb[]>
    ) => {
      state.breadcrumbs = action.payload;
    },
  },
});

//
export const {
  updateScroll,
  updateSize,
  updateDialog,
  updateDrawer,
  toggleDrawer,
  updateBreadcrumbs,
} = common.actions;
//
// call in component
export const SELECT_SCROLL = () =>
  useAppSelector((state: AppState) => state.common.scroll);

export const SELECT_WINDOW_SIZE = () =>
  useAppSelector((state: AppState) => state.common.size);

export const SELECT_DIALOG = () =>
  useAppSelector((state: AppState) => state.common.dialog);

export const SELECT_DRAWER_VALUE = () =>
  useAppSelector((state: AppState) => state.common.drawer);

export const SELECT_BREAD_CRUMBS = () =>
  useAppSelector((state: AppState) => state.common.breadcrumbs);

export default common.reducer;
