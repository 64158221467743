const INDEX = "/dashboard";
const GENERAL_REPORT_DETAILS = `${INDEX}/general-report-details`;

const HELP = `${INDEX}/help`;
const HELP_WEB = `${HELP}/web`;
const HELP_WEB_GENERAL_REPORT = `${HELP_WEB}/general-report`;
const HELP_WEB_NOTIFICATIONS = `${HELP_WEB}/notifications`;
const HELP_WEB_EMAIL_REGISTRATION = `${HELP_WEB}/email-registration`;
const HELP_WEB_MEMBER_REGISTRATION = `${HELP_WEB}/member-registration`;
const HELP_WEB_PASSWORD_SETUP = `${HELP_WEB}/password-setup`;
const HELP_WEB_TAGS = `${HELP_WEB}/tags`;
const HELP_WEB_SUPPORT = `${HELP_WEB}/supports`;

const MEMBERS = `${INDEX}/members`;
const MEMBER = {
  pathname: `${MEMBERS}/:id`,
  create: (id: string, tab: "info" | "user-permission") =>
    `${MEMBERS}/${id}?tab=${tab}`,
};
const INVITATIONS = `${INDEX}/invitations`;
const INVITATION = {
  pathname: `${INVITATIONS}/:id`,
  create: (id: string) => `${INVITATIONS}/${id}`,
};
const PROJECTS = `${INDEX}/projects`;
const PROJECTS_PROJECT = {
  pathname: `${PROJECTS}/:id`,
  create: (id: string, area?: string) =>
    area ? `${PROJECTS}/${id}?area=${area}` : `${PROJECTS}/${id}`,
};

const PROJECTS_PROJECT_TAGS = {
  pathname: `${PROJECTS_PROJECT.pathname}/tags`,
  create: (id: string, area?: string) =>
    area
      ? `${PROJECTS}/${id}/tags?area=${area}`
      : `${PROJECTS_PROJECT.create(id)}/tags`,
};

const NOTIFICATIONS = `${INDEX}/notifications`;
const NOTIFICATIONS_SETTING = `${NOTIFICATIONS}/setting`;
const NOTIFICATIONS_TAG = {
  pathname: `${NOTIFICATIONS}/tag/:publicAddress`,
  create: (publicAddress: string) => `${NOTIFICATIONS}/tag/${publicAddress}`,
};

const TAGS = `${INDEX}/tags`;
const TAG = {
  pathname: `${TAGS}/:publicAddress`,
  create: (publicAddress: string) => `${TAGS}/${publicAddress}`,
};

const MOVED_TAGS = `${INDEX}/moved-tags`;
const SUPPORT = `${INDEX}/support`;
const SUPPORT_TICKETS = `${SUPPORT}/tickets`;

const SUPPORT_TICKETS_TICKET = {
  pathname: `${SUPPORT_TICKETS}/:id`,
  create: (id: string) => `${SUPPORT_TICKETS}/${id}`,
};

const DASHBOARD_LINKS = {
  INDEX,
  GENERAL_REPORT_DETAILS,
  MEMBERS,
  MEMBER,
  INVITATIONS,
  INVITATION,
  PROJECTS,
  PROJECTS_PROJECT,
  PROJECTS_PROJECT_TAGS,
  NOTIFICATIONS,
  MOVED_TAGS,
  NOTIFICATIONS_SETTING,
  TAGS,
  SUPPORT,
  SUPPORT_TICKETS,
  HELP,
  HELP_WEB,
  HELP_WEB_GENERAL_REPORT,
  HELP_WEB_NOTIFICATIONS,
  HELP_WEB_EMAIL_REGISTRATION,
  HELP_WEB_MEMBER_REGISTRATION,
  HELP_WEB_PASSWORD_SETUP,
  HELP_WEB_TAGS,
  HELP_WEB_SUPPORT,
  SUPPORT_TICKETS_TICKET,
  TAG,
  NOTIFICATIONS_TAG,
};
export default DASHBOARD_LINKS;
